body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  --purpleBg: #454CC9;
  --purpleBgLight: #F0EBFB;
  --purpleLight: #6469c7;
  --blue: #1e90ff;
  --white: #ffffff;
  --textGrey: #B2B2B2;
  --purpleHeading: #F1EBFF;
}

/* forgot password text css */
.forgot_pass {
  float: right;
  margin-top: -34px;
  color: var(--purpleBg);
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.button_common {
  background-color: var(--purpleBg);
  color: var(--white);
  height: 2.5rem;
  width: 80%;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: large;
  border-radius: 15px;
}

.button_common:hover {
  background-color: var(--purpleLight) !important;
  color: rgb(7, 7, 7) !important;
}

.formClass {
  width: 68%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 15px;
  /* font-size: large ; */
}

.dont_acc {
  text-align: center;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
}

.font_size {
  font-size: 1.25rem;
}

@media screen and (max-width : 992px) {
  .formClass {
    width: 78%
  }
}

.ant-layout-header {
  background-color: var(--white) !important;
}

.ant-select-arrow {
  color: var(--purpleBg) !important;
}

.ant-select.ant-select-single.ant-select-show-arrow:hover {
  border-color: var(--purpleBg) !important;
}

.ant-layout-header {
  padding: 0 30px;
}

.Maincard {
  box-shadow: 0px 5px 25px #6C3BD51A;
  border-radius: 10px;
}

.user-profile {
  display: inline-flex;
  height: 40px;
  width: 6.5rem !important;
  border: 1px solid rgb(207, 205, 205);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

/* .admin{
  border: 1px solid black;
  height: 20px;
} */

@media screen and (max-width : 900px) {
  .user-profile {
    width: 15rem;
  }
}

.ant-dropdown-trigger .ant-dropdown-open {
  background-color: rgb(31, 207, 207) !important;
}

span.ant-menu-title-content>a {
  text-decoration: none !important;
}

.ant-menu-item-selected {
  background-color: var(--purpleBgLight) !important;
  color: var(--purpleBg) !important
}

.ant-radio .ant-wave-target .ant-radio-checked {
  background-color: var(--purpleBg) !important;
}

.notiText {
  color: var(--textGrey);
  font-size: 15px;
}

.ant-radio-wrapper:hover {
  border-color: var(--purpleBg) !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: var(--purpleHeading) !important;
  color: var(--purpleBg) !important
}

.viewButton {
  background-color: var(--purpleBg);
  color: var(--white);
  height: 2rem;
  /* width: 36%; */
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 15px;
}

.viewButton:hover {
  color: white !important;
  border-color: #6240C9 !important;
}

.ant-pagination .ant-pagination-item-active a {
  background-color: var(--purpleBg);
  border-color: none !important;
  border-radius: 3px !important;
  color: var(--white) !important
}

.purpleButton {
  background-color: var(--purpleBg) !important;
  color: white
}

.purpleButton:hover {
  background-color: var(--purpleLight) !important;
  color: white !important;
  border: none;
}

.logout_dropdown {
  background-color: var(--purpleLight) !important;
}

.input_width {
  width: 50%;
}

.w-48 {
  width: 48% !important;
}

.ant-modal-title {
  text-align: center;
}

.w-10 {
  width: 9% !important;
}

.highcharts-area {
  fill: var(--purpleBg) !important;
}

path.highcharts-point.highcharts-color-0 {
  fill: var(--purpleBg) !important;
}

rect.highcharts-point {
  fill: var(--purpleBg) !important;
}

.ant-pagination-item-active {
  border: none !important;
}

.ant-table-row-selected {
  background-color: #6240C9 !important;
}

span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  display: none !important;
}

.ant-form-item-control-input-content>div {
  justify-content: space-between;
}

@media screen and (max-width : 425px) {
  .button_common {
    width: 64%;
  }

  .font_size {
    font-size: 1rem;
  }

  .input_width {
    width: 100%;
  }

}

.ant-spin.ant-spin-lg.ant-spin-spinning.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width : 2200px) {
  .button_common {
    width: 10%;
  }

  .font_size {
    font-size: 2rem;
  }
}

.ql-editor {
  min-height: 300px !important;
}


.ant-table-row-selected>.ant-table-cell {
  background: #E8E9FF !important;
}

.ant-pagination .ant-pagination-item-active a {
  border-radius: 50% !important;
}

.anticon-search>svg {
  color: #6240C9 !important
}

.loader {
  position: fixed;
  z-index: 9999999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 10%;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/*  initial loading page css  */
.initialLoading {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  /* Adjust the image size as needed */
  background-position: center;
  /* Center the background image */
  background-repeat: no-repeat;
}

.initialLoading>img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14rem;
}

.ant-switch.ant-switch-checked {
  background: #6240C9 !important
}

.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(60px) translateX(55px) !important;
}